import bpmnlogo from '../images/BPMN-logo.svg';
import maudelogo from '../images/maude-logo.png';
import sceniclogo from '../images/scenic-logo.png';
import step1logo from '../images/step1.png';
import step2logo from '../images/step2.png';
import step3logo from '../images/step3.png';
import step4logo from '../images/step4.png';


function setImage(imgSrc,imgId){
    var imgTag = document.getElementById(imgId);
    imgTag.src = imgSrc;
}

export default function setImages(page){
    setImage(sceniclogo,'scenic-logo');
    if(page == 'index'){
        setImage(bpmnlogo,'bpmn-logo');
        setImage(maudelogo,'maude-logo');
        setImage(step1logo,'step1');
        setImage(step2logo,'step2');
        setImage(step3logo,'step3');
        setImage(step4logo,'step4'); 
    }
}